import React from 'react'
import {useEffect,useState, useRef} from 'react';
import {returnNext} from './functions.js'
//простой слайдер для показа div блоков
//параметры
//arr=Array - массив со слайдами
//autoplay=Int|false - нужна ли автопрокрутка
//showpoints=true|false - показывать ли точки со слайдером
function Slider(props)
{
const [n,setN]=useState(0);
const [stopTimer,setStopTimer]=useState(false)
const stopRef=useRef(null)
stopRef.current=stopTimer
const nRef=useRef(null)
nRef.current=n
const arr=props.arr
const item=arr[nRef.current]
//generate points
const points=<div className="slider_points">{arr.map((item,i)=><div key={i} className={(i===n)?"slider_point slider_point_active":"slider_point"} onClick={()=>{setStopTimer(true);setN(i);}} />)}</div>

//mobile swipe
const [touchStart, setTouchStart]=useState(0);
const [touchEnd, setTouchEnd]=useState(0);
function etouchStart(e) {setTouchStart(e.targetTouches[0].clientX);e.stopPropagation() }
function etouchMove(e) {;setTouchEnd(e.targetTouches[0].clientX);}
function etouchEnd(e)
{
  //if (Math.abs(touchStart-touchEnd)<10 || touchEnd===0) return false;
let next=(touchStart-touchEnd>50 && touchEnd!==0)?returnNext(false,nRef.current,arr.length):(touchStart-touchEnd<-50 && touchEnd!==0)?returnNext(true,nRef.current,arr.length):false
//console.log(n,next)
setTouchEnd(0)
setTouchStart(0)
  if (next!==false) 
   {
setStopTimer(true) //stop timer when slide by touch
   setN(next)
   }
}
//end swipe

useEffect(() => {
  
let interval=window.setInterval(()=>
	{
if (typeof props.autoplay==='undefined' || props.autoplay===null || stopRef.current===true) return;
const next=returnNext(false,nRef.current,arr.length)
setN(next)
	},props.autoplay)
  return () => {
    clearInterval(interval);
  };// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
return(
<div onTouchStart={(e)=>etouchStart(e)} onTouchEnd={(e)=>etouchEnd(e)} onTouchMove={(e)=>etouchMove(e)} style={{touchAction:'pan-y'}}>
{item}
{(props.showpoints!==false)?points:''}
</div>
)
}

export default Slider;