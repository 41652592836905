import {useEffect} from 'react';
var ua = navigator.userAgent.toLowerCase()
export const isMobile=(/iphone/.test(ua)) || (/android/.test(ua)) // || (/ipad/.test(ua))
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replace) 
{
return this.split(search).join(replace);
}
 //for animation scroll of div
const easing=(x)=>1-Math.pow(1 - x, 4)
export function animateDivScroll(target, left, duration,scrollTop=false)
{ 
let pos,start,aFrame
const maxScroll=(scrollTop?(target.scrollHeight-target.clientHeight):(target.scrollWidth-target.clientWidth))
const init=(scrollTop?target.scrollTop:target.scrollLeft)
const amountToScroll=init-left;
  function step(curtime)
   {
  if (start===undefined) start=curtime
const elapsed=curtime-start
const progress=elapsed/duration
const eProgress=easing(progress)
pos=init-amountToScroll*Math.min(eProgress,1)
  if (scrollTop) target.scrollTo({top:pos});
  else target.scrollTo({left:pos});
  if (init!==maxScroll && target.scrollLeft===maxScroll)
   {
window.cancelAnimationFrame(aFrame)
return
   }
  if (elapsed<duration) aFrame =window.requestAnimationFrame(step);
    }
aFrame=window.requestAnimationFrame(step);
}


export function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}
export function elementVisible(el)
{
el=document.getElementById(el)
const rect = el.getBoundingClientRect();
return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
}
//������� ����������, ��� ��������� ����� ��� ��������
export function returnNext(prev,id,count)
{ 
return (prev?(id+count-1)%count:(id+1)%count)
}